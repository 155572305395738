/**
 * Partner component
 */
export default function () {
    var slideIndex = 4;

    var init = function () {
        parse();
    };

    var parse = function () {
        $('.partners-carousel').each(function (index) {
            var target = $(this);
            var id = target.attr('data-id');

            var option = configure(id);
            apply('#partners-carousel-' + id, option);
        });
    };

    var configure = function (id) {
        let option = {
            rows: 0,
            slidesToShow: 8,
            slidesToScroll: 1,
            autoplay: true,
            prevArrow: $('#partners-control-prev-' + id),
            nextArrow: $('#partners-control-next-' + id),
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                }
            ]
        }

        return option;
    };

    var apply = function (target, option) {
        $(target).slick(option);
    };

    init();

}
