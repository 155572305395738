/**
 * Modal component
 */
export default function () {

    let init = function() {
        bindPrint();
    };

    let printDiv = function (eTarget, eTargetTitle) {
        let a = window.open('', '_blank', );
        let title = eTargetTitle.html();
        a.document.write('<html>');
        a.document.write('<header></header>');
        a.document.write('<body><style>.no-print { display:none; } img { width: 100%; }</style><h1>');
        a.document.write((title !== undefined) ? title : '');
        a.document.write('</h1>');
        a.document.write(eTarget.html());
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    };

    let bindPrint = function () {
        $(".print-div").each(function (index) {
            let element = $(this);

            element.on('click', function (event) {
                event.preventDefault();
                let eTarget = $($(this).attr("data-target"));
                let eTargetTitle = $($(this).attr("data-target-title"));

                printDiv(eTarget, eTargetTitle);
            })
        })
    };

    init();
}
