/**
 * Event component
 */
export default function () {

    var init = function () {
        parse();
        initAjaxEvent();
    };

    var parse = function () {

        $('.events-carousel').each(function () {
            var target = $(this);
            var id = target.attr('data-id');

            apply('#events-carousel-' + id, id);
        });
    };

    var apply = function (target, id) {
        $(target).slick({
            rows: 0,
            infinite: false,
            centerMode: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            prevArrow: $('#events-control-prev-' + id),
            nextArrow: $('#events-control-next-' + id),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        centerMode: false,
                        infinite: false,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        centerMode: false,
                        infinite: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        centerMode: true,
                        infinite: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    };

    let loadSession = function (state, eventType, targetUrl) {
        $.ajax({
            type: "POST",
            url: targetUrl,
            data: 'showPartnerEvents=' + state + "&typeevent=" + eventType,
            success: function (msg) {
                let tab = msg.split('~');
                $("#ajaxevent").html(tab[0]);

            }
        });
    };

    let initAjaxEvent = function () {
        $('.ajax-event-trigger').on('change', function (event) {
            let elem = $(this);
            let target = elem.attr('data-target');
            let elemTarget = $(target);

            let targetUrl = elemTarget.attr('data-target-url');
            let eventType = elemTarget.attr('data-event-type');
            let state = elem.prop('checked');
            if (state) {
                $('.ajax-event-trigger').each(function(index) {
                    $(this).prop('checked', state);
                });
            }

            loadSession(state, eventType, targetUrl);
        });
    };

    init();

}
