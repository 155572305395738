/**
 * Main Js files
 */

/* Dependencies */
import $ from 'jquery';
require('popper.js');
require('bootstrap');
require('slick-carousel');

require('swiper/dist/css/swiper.min.css');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');

/* Components */
import carousel from './components/carousel';
import event from './components/event';
import modal from './components/modal';
import navbar from './components/navbar';
import partner from './components/partner';
import { Search } from './components/search';
import card from './components/card';
import swiper from "./components/swiper";
import { Sidebar } from "./components/sidebar";
import printdiv from "./components/printdiv";
import newsletter from "./components/newsletter";
import contact from "./components/contact";
import { OverlayBtn } from "./components/overlayBtn";
import { Messagebar } from "./components/messagebar";
import { MapCanvas } from "./components/mapCanvas";

/* Utils */
import imageResizer from "./imageResizer.js";

$(document).ready(function(){
    carousel();
    navbar();
    modal();
    event();
    partner();
    card();
    swiper();
    Sidebar.init();
    printdiv();
    newsletter();
    contact();
    imageResizer();
    Search.init();
    OverlayBtn.init();
    Messagebar.init();
    MapCanvas.init();

    setTimeout(function(){
        Search.searching();
    }, 0.001);

    $('.iframe-asynch-load').each(function() {
        let src = $(this).attr('data-src');
        $(this).attr('src', src);
    });

    $('#agendaSanteModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var agendaSanteId = button.data('agendasanteid');
        $('#agendaSanteModal iframe').attr('src', agendaSanteId);
    });

    $('#agendaSanteModal').on('hidden.bs.modal', function () {
        $('#agendaSanteModal iframe').removeAttr('src');
    })

    /**
     * Survey popup action
     */
    $('#other-services').on("show.bs.modal", function (e) {
        $('.surveyList').show();
        $('.surveyDisplay')
            .hide()
            .removeClass('d-flex');
    });

    $('.surveyBackToList').on("click", function (e) {
        $('.surveyList').show();
        $('.surveyDisplay')
            .hide()
            .removeClass('d-flex');
    });

    $('.surveyClick').on("click", function (e) {
        let surveyUrl = $(this).attr("data-surveyurl");

        let iframe = document.getElementById('surveyIframe');
        iframe.src = surveyUrl;

        $('.surveyList').hide();
        $('.surveyDisplay')
            .show()
            .addClass('d-flex');
    });

    $('#agenda').on('show.bs.modal', function (event) {
        let iframe = $('#agenda').find("iframe");
        iframe.attr('src', iframe.attr('data-src'));
    });

});

$(document).ready(function () {
    // let mapElement = document.getElementById('map-canvas');
    //
    // if (null !== mapElement) {
    //     Map.loadGoogleMapsApi().then(function (googleMaps) {
    //         Map.createMap(googleMaps, mapElement);
    //     });
    // }

    var isFirefox = typeof InstallTrigger !== 'undefined';

    if (isFirefox === true) {
        var executer = false;

        $("a.cursor-archive").click(function () {
            if (executer === false) {
                executer = true;
                setTimeout(function () {
                    $("#wrapperIframe").find("iframe").attr("id", "iframeOnedoc");
                    setTimeout(function () {
                        document.getElementById('iframeOnedoc').src = document.getElementById('iframeOnedoc').src;
                        $('#iframeOnedoc').css('height', '500px');
                    }, 100);
                }, 100);
            }
        });
    }

    let iframeSA = $('#iframe-specific-sport-altitude');

    if (iframeSA.length > 0) {
        iframeSA.load(function () {
            setTimeout(function () {
                document.getElementById('topWrap').style.display = 'none';
                document.getElementById('tab-bar').style.display = 'none';
            }, 10000);
        });
    }

    $(document).on('click', '#iframe-specific-sport-altitude', function () {
        var topWrap = $('#topWrap');
        var tabBar = $('#tab-bar');
        if(topWrap.is(':visible')){
            topWrap.css('display', 'none');
        }
        document.getElementById('topWrap').style.display = 'none';
        document.getElementById('tab-bar').style.display = 'none';
        if(tabBar.is(':visible')){
            tabBar.css('display', 'none');
        }
    });
});


