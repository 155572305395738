/**
 * Contact form component
 */
import {Messagebar} from "./messagebar";

export default function () {
    var isShift = false;
    var seperator = ".";
    var birthday = $("#birthday");
    
    var init = function () {
        var the_terms = $("#theterms");
        the_terms.on("click", function () {
            if ($(the_terms).is(":checked")) {
                enableSend();
            } else {
                disableSend();
            }
        });


        listenToBirthdayChange();

    };

    var listenToBirthdayChange = function () {
        
        //Only allow Numeric Keys.
        $(birthday).on("keydown", function (e) {
            return IsNumeric(this, e.keyCode);
        });

        //Validate Date as User types.
        $(birthday).on("keyup", function (e) {
            //ValidateDateFormat(this, e.keyCode);
        });
    }

    function IsNumeric(input, keyCode) {
        if (keyCode == 16) {
            isShift = true;
        }
        //Allow only Numeric Keys.
        if (((keyCode >= 48 && keyCode <= 57) || keyCode == 8 || keyCode <= 37 || keyCode <= 39 || (keyCode >= 96 && keyCode <= 105)) && isShift == false) {
            if ((input.value.length == 2 || input.value.length == 5) && keyCode != 8) {
                input.value += seperator;
            }
 
            return true;
        }
        else {
            return false;
        }
    };
 
    function ValidateDateFormat(input, keyCode) {
        var dateString = input.value;
        if (keyCode == 16) {
            isShift = false;
        }
        var regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;
 
        //Check whether valid dd/MM/yyyy Date Format.
        //todo dipsay hidde error message invalide date
        if (regex.test(dateString) || dateString.length == 0) {
            ShowHideError(input, "none");
        } else {
            ShowHideError(input, "block");
        }
    };

    //Todo potentiellement de la validation de formulaire vérification du mail
    // avoir quelque chose de plus propre que de fermer le formulaire et mettre un message d'erreur sur la front page pour le recaptcha
    // "problème" est pour la traduction depuis le js et devoir rajouter des trads
    // var formValidation = function () {

    //     return true;
    // };

    var disableSend = function () {

        $("#submitBtn").attr("disabled", "disabled");
    };

    var enableSend = function () {

        $("#submitBtn").removeAttr("disabled");
    };

    init();

    let informationForm = $('#informations_form');

    informationForm.submit(function(e) {
        e.preventDefault();
        let warningCheckBirthday = $('#warningCheckBirthday');
        let birthday = $("#birthday");

        warningCheckBirthday.hide();
        birthday.removeClass("date_input_error");

        if (birthday.val() === '') {
            birthday.addClass("date_input_error");
            warningCheckBirthday.show();
            warningCheckBirthday.html(error_input_required);
            return false;
        } else {
            var birthday_array = $('#birthday').val().split('.'),
                day = birthday_array [0],
                month = birthday_array[1],
                year = birthday_array[2];
            var dayIsNumber = /^\d+$/.test(day),
                monthIsNumber = /^\d+$/.test(month),
                yearIsNumber = /^\d+$/.test(year);
            if (dayIsNumber && monthIsNumber && yearIsNumber) {
                var date_birthday = new Date(year, month - 1, day);
                var date_now = new Date();
                if ((date_birthday < date_now)) {
                    let dataSerialized = $(this).serializeArray();
                    let dataFormated = {};
                    $.each(dataSerialized, function (index, value) {
                        dataFormated[value.name] = value.value;
                    });

                    let infoform = $('#informations_form');

                    $.ajax({
                        type: "POST",
                        url: infoform.attr("action"),
                        dataType: "json",
                        data: dataFormated
                    }).done(function (response) {
                        if (response.success === true) {
                            $("#contact").modal('hide');
                            infoform.trigger('reset');
                            Messagebar.addMessage(response.label, response.message);
                        } else {
                            Messagebar.addMessage(response.label, response.message);
                        }
                    });

                    return true;
                } else {
                    birthday.addClass("date_input_error");
                    warningCheckBirthday.show();
                    warningCheckBirthday.html(error_date_now);
                    return false;
                }

            } else {
                birthday.addClass("date_input_error");
                warningCheckBirthday.show();
                warningCheckBirthday.html(error_date_format);
                return false;
            }
        }
    });

}
