/**
 * Map canvas
 */
class MapCanvas {

    static init() {
        let id = "map-canvas";
        let mapCanvas = $('#' + id);

        if (mapCanvas.length > 0) {
            let gps = [
                mapCanvas.attr('data-lat'),
                mapCanvas.attr('data-long')
            ]
            let map = L.map(id).setView(gps, 17);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);

            var customPopup = '<div class="text-center"><img height="60px" src="' + mapCanvas.attr("data-logo") + '" alt="' + mapCanvas.attr("data-center") + '"><br/>' + mapCanvas.attr("data-center") + '</div>';

            var customOptions =
                {
                    'maxWidth': '320',
                    'minWidth': '160'
                };

            L.marker(gps)
                .bindPopup(customPopup, customOptions)
                .addTo(map)
                .openPopup();
        }
    }
}

export { MapCanvas }
