/**
 * resize images for the banner in carousel.html.twig
 */
export default function () {

    var init = function () {
        var img = $('#carouselHomepage .img-fluid');
        img.each(function () {
            var $this = $(this),
                width = $this.prop("naturalWidth"),
                height = $this.prop("naturalHeight"),
                ratio = width / height;


            if (!Number.isNaN(ratio)) {
                if (width < height) {
                    $this.addClass('portrait');
                } else {
                    $this.addClass('landscape');
                }
            }

        });
    };

    init();

}