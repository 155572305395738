/**
 * Overlay button component
 */
const url = 'https://delta-data.ch/deltauitoolkit/agenda'
const templateBtn = '<button class="btn btn-primary overlayBtn" data-toggle="modal" data-target="#modalRdv" >' +
    '<i class="fas fa-calendar-plus"></i> Prendre rendez-vous' +
    '</button>';

const templateModal = '<div id="modalRdv" class="modal" tabindex="-1" role="dialog" aria-labelledby="agendaOverlay" aria-hidden="true">' +
    '   <div class="modal-dialog modal-lg" role="document">' +
    '       <div class="modal-content">' +
    '           <div class="modal-header">' +
    '               <button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
    '                   <span aria-hidden="true">&times;</span>' +
    '               </button>' +
    '           </div>' +
    '           <div class="modal-body">' +
    '               <iframe width="100%" frameborder="0" src="' + url + '"></iframe>' +
    '           </div>' +
    '           <div class="modal-footer"></div>' +
    '       </div>' +
    '   </div>' +
    '</div>';


class OverlayBtn {

    static init() { }

    static apply() {
        $('body')
            .append(templateModal)
            .append(templateBtn);
    }

}

export { OverlayBtn }
