/**
 * Messagebar component
 */

class Messagebar {

    static init() {};

    static addMessage(label, message) {
        let model = '<div class="alert alert-' + label +'">' +
            '<a href="#" data-dismiss="alert" class="close">×</a>' +
            message +
            '</div>'
        ;
        $('.messagebar').append(model);
    }

    static removeAllMessage() {
        $('.messagebar').empty();
    }

}

export { Messagebar }
