/**
 * Modal component
 */
export default function () {

    var init = function () {
        $('.modal').each(function (index) {
            $(this).appendTo('body');
        });
    };



    init();

}
