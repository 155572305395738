/**
 * Card component
 */
export default function () {

    $('.card-accordion').each(function (index) {
        var elem = $(this);
        var height = elem.attr('data-height');

        if (elem.innerHeight() > height) {
            elem.addClass('closed')
                .css('height', height + 'px')
            ;

            elem.find('.card-trigger').each(function (index) {

                $(this).on('click', function (element) {

                    if (elem.hasClass('closed')) {
                        elem.removeClass('closed')
                            .addClass('open')
                            .css('height', 'auto')
                        ;
                    } else {
                        elem.removeClass('open')
                            .addClass('closed')
                            .css('height', height + 'px')
                        ;
                    }

                });

            });
        }

    });

}
