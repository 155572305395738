/**
 * Search component
 */
import card from "./card";
import $ from "jquery";

class Search {

    static init() {
        this.apply();
    };

    static apply() {
        $('body').on('change', '.search-ajax select', (elem) => {
            this.searching();
        });
    };

    static searching() {
        const inactive = false;
        var path = $('.search-ajax').attr('data-url');

        var dataPost = {
            search_center: $('.search_center option:selected').val(),
            search_lastname: $.trim($('.search_name option:selected').attr('data-last-name')),
            search_firstname: $.trim($('.search_name option:selected').attr('data-first-name')),
            search_language: $('.search_language option:selected').val(),
            search_sex: $('.search_sex option:selected').val(),
            search_specialties: $('.search_specialties option:selected').val()
        };

        $.ajax({
            type: "POST",
            url: path,
            data: dataPost,
            success: function (data) {
                $("#search-specialties-container").html(data);

                card();
            }
        });
    };

}

export { Search }
