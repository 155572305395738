/**
 * Newsletter component
 */
export default function () {

    let init = function () {
        bindForm();

        $('#newsletter-desabonnement').hide();

        bindSwitch();
    };

    let bindSwitch = function () {
        $('.newsletter-switch').on('click', function () {
            $('#newsletter-abonnement').toggle();
            $('#newsletter-desabonnement').toggle();
        });
    };

    let bindForm = function () {

        addNewsletter();

        deleteNewsletter();
    };

    let validateEmail = function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    let addNewsletter = function() {

        $('#newsletter-subscribe').on('submit', function (event) {
            let email = $(this).find('.newsletter-email').val();
            let id = $(this).attr('id');

            if ('' !== email && validateEmail(email)) {
                $.ajax({
                    type: 'POST',
                    url: $(this).attr('action'),
                    data: {
                        email: email
                    }
                }).done(function (data) {
                    if (data.message === 1) {
                        $('.newsletter-message').html('<span class="error-message">' + message_success_subscribe + '</span>');
                    } else {
                        $('.newsletter-message').html('<span class="error-message">' + message_exist_mail + '</span>');
                    }
                });
            } else {
                $('.newsletter-message').html('<span class="error-message">' + message_verify_input + '</span>');
            }

            event.preventDefault();
        });
    };

    let deleteNewsletter = function() {

        $('#newsletter-unsubscribe').on('submit', function (event) {
            let email = $(this).find('.newsletter-email').val();
            let id = $(this).attr('id');

            if ('' !== email && validateEmail(email)) {
                $.ajax({
                    type: 'POST',
                    url: $(this).attr('action'),
                    data: {
                        email: email
                    }
                }).done(function (data) {
                    if (data.message === 1) {
                        $('.newsletter-message').html('<span class="error-message">' + message_success_unsubscribe + '</span>');
                    } else {
                        $('.newsletter-message').html('<span class="error-message">' + message_inexist_mail + '</span>');
                    }
                });
            } else {
                $('.newsletter-message').html('<span class="error-message">' + message_verify_input + '</span>');
            }

            event.preventDefault();
        });
    };

    init();
}
