/**
 * Carousel component
 */
export default function () {

    var switchTitle = function (target) {
        var title = $(target.find('.carousel-slide-title')[0]);
        $('.navbar-band-push').html(title.html());
        title.hide();
    };

    var init = function () {
        var firstTarget = $($('.carousel-item')[0]);
        switchTitle(firstTarget);

        $('#carouselHomepage').on('slide.bs.carousel', function (event) {
            var target = $(event.relatedTarget);
            switchTitle(target);
        });

        $('.carousel').each(function () {
            if ($(this).find('.carousel-item').length === 1) {
                $(this).find('.carousel-control-prev').hide();
                $(this).find('.carousel-control-next').hide();
            }
        })
    };

    init();
}
