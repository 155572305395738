/**
 * Sidebar component
 */
const sidebar = $('.sidebar');
const sidebarMask = $('.sidebar-mask');
const sidebarBtn = $('.sidebar-btn, .sidebar-mask');

class Sidebar {

    static init() {
        let from = this.getUrlParameter('from');

        if ('sidebar' === from) {
            this.close();
        }

        sidebarBtn.on('click', (elem) => {
            if (sidebar.hasClass('opened')) {
                this.close();
            } else {
                this.open();
            }
        });
    }

    static getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    static close() {
        sidebarMask.hide('fast');
        sidebar.removeClass('opened');
        sidebarBtn.removeClass('closed');
    }

    static open() {
        sidebar.addClass('opened');
        sidebarBtn.addClass('closed');
        sidebarMask.show('fast');
    }
}

export { Sidebar }
